import React, { useState, useCallback } from 'react'
import moment from 'moment'
import {
    createStyles,
    withStyles,
    Tooltip,
    CircularProgress,
    Typography,
    Button,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
} from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { t } from 'i18next'
import { MuiProps, muiOptions, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { OrdreDeFabrication, ReferenceProduit } from '../../models'
import StatutSelector from './statutSelector'
import OfStatutChip from '../../../common/ofStatutChip'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import api from '../../../infrastructure/api'

type HeaderProps = {
    of: OrdreDeFabrication
    referenceProduit: ReferenceProduit
    handleChangeStatut: (ordreDeFabricationNumero: string, produitCode: string, newStatut: string) => void
}

function Header({ of, referenceProduit, handleChangeStatut, classes }: MuiProps & HeaderProps) {
    const [loading, setLoading] = useState<{ state: boolean; type: string | null }>({ state: false, type: null })

    const handlePrint = useCallback(
        async (type: string, endpoint: string) => {
            try {
                setLoading({ state: true, type })
                let response = await api.post(
                    `${endpoint}/generate/${type}/${of.numero}/${referenceProduit.produitCode}`,
                )
                window.open(`${api.apiUrl}${response.url}`)
            } catch (error) {
                console.error(`Error generating ${type} label:`, error)
            } finally {
                setLoading({ state: false, type: null })
            }
        },
        [of.numero, referenceProduit.produitCode],
    )

    const renderButton = (type: string, label: string, pdfOrCsv: string, onClick: () => void) => (
        <Tooltip title={t(label)}>
            <Button variant="contained" className={classes.button} onClick={onClick} disabled={loading.state}>
                {loading.state && loading.type !== type ? (
                    <Icons.PrintDisabled />
                ) : loading.state && loading.type === type ? (
                    <CircularProgress size={20} />
                ) : (
                    <Icons.Print />
                )}
                {pdfOrCsv}
            </Button>
        </Tooltip>
    )

    const renderRightHeaderContent = () => {
        if (!of.paysDeFabrication || !of.midCode) {
            return (
                <div className={classes.rightHeaderMessage}>
                    <Typography variant="subtitle1" className={classes.message}>
                        <b>{t('product.downloadUnavailable')}</b>
                    </Typography>
                    <Button variant="contained" className={classes.button} onClick={() => history.back()}>
                        <Icons.ArrowBack />
                        {t('common.back')}
                    </Button>
                </div>
            )
        }

        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align="center">
                            <b>{t('product.productTitle')}</b>
                        </TableCell>
                        <TableCell align="center">
                            <b>{t('product.logisticTitle')}</b>
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell align="center">
                            {renderButton('product', 'product.productPdf', 'PDF', () =>
                                handlePrint('product', 'ofProduitPdf'),
                            )}
                            {renderButton('csvProduct', 'product.productCsv', 'CSV', () =>
                                handlePrint('csvProduct', 'ofProduitCsv'),
                            )}
                        </TableCell>
                        <TableCell align="center">
                            {renderButton('logistic', 'product.logisticPdf', 'PDF', () =>
                                handlePrint('logistic', 'ofProduitPdf'),
                            )}
                            {renderButton('csvLogistic', 'product.logisticCsv', 'CSV', () =>
                                handlePrint('csvLogistic', 'ofProduitCsv'),
                            )}
                        </TableCell>
                        <TableCell align="center">
                            <Button variant="contained" className={classes.button} onClick={() => history.back()}>
                                <Icons.ArrowBack />
                                {t('common.back')}
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }

    return (
        <div className={classes.header}>
            <div className={classes.leftHeader}>
                <OfStatutChip className={classes.numero} label={of.numero} statut={referenceProduit.statut} />
                <StatutSelector
                    handleChange={handleChangeStatut}
                    ordreDeFabricationNumero={of.numero}
                    produitCode={referenceProduit.produitCode}
                    selectedItem={referenceProduit.statut}
                />
                <Typography variant={'subtitle1'} className={classes.typoHeader}>
                    {t('detailsOf.creation') + ' ' + moment(of.dateCreation).format(t('date.format.date'))}
                </Typography>
                <Tooltip title={t('product.manufacturer')}>
                    <Typography variant={'subtitle1'} className={classes.typoHeader}>
                        {of.fabricant.label}
                    </Typography>
                </Tooltip>
                <Tooltip title={t('product.season')}>
                    <Typography variant={'subtitle1'} className={classes.typoHeader}>
                        {!!of.saison ? of.saison.description : ''}
                    </Typography>
                </Tooltip>
                <Tooltip title={t('product.typeWO')}>
                    <Typography variant={'subtitle1'} className={classes.typoHeader}>
                        {of.typeOrdreDeFabrication && t('typeOf.'.concat(of.typeOrdreDeFabrication.toLowerCase()))}
                    </Typography>
                </Tooltip>
                <Typography variant={'subtitle1'} className={classes.typoHeader}>
                    {of.groupage}
                </Typography>
            </div>
            <div className={classes.rightHeader}>{renderRightHeaderContent()}</div>
        </div>
    )
}

let styles = () =>
    createStyles({
        header: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        leftHeader: {
            ...defaultStyles.flexRow,
            alignItems: 'center',
            flexGrow: 1,
        },
        rigntHeder: {
            ...defaultStyles.flexRow,
            flexGrow: 1,
        },
        rightHeaderMessage: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
        },
        numero: {
            marginLeft: '40px',
            marginRight: '20px',
        },
        typoHeader: {
            margin: '5px 20px',
        },
        button: {
            ...(defaultStyles.button as CSSProperties),
            ...(defaultStyles.buttonNavigation as CSSProperties),
        },
        message: {
            marginRight: '10px',
        },
    })

export default withStyles(styles, muiOptions)(Header)
